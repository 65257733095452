.navbar-enabler-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: var(--bg-color-cards);
  top: 1.5rem;
  z-index: 11;
  left: 0px;
  transition: 0.5s;
  width: 100px;
  height: 70px;
  padding: 45px 55px;
  border-radius: 0px 10px 10px 0;
  box-shadow: 8px 0 10px 0px rgba(0, 0, 0, 0.4);

  &:hover {
    cursor: pointer;
  }
}

#navbar-enabler:not(:checked) + .navbar-enabler-icon:hover .arrow-one > div {
  animation: 1s 0.2s fadeInOut linear infinite;
}

#navbar-enabler:not(:checked) + .navbar-enabler-icon:hover .arrow-two > div {
  animation: 1s fadeInOut linear infinite;
}

.navbar-enabler-icon > div {
  transition: 0.5s;
}

.navbar-enabler-icon > div > div {
  width: 30px;
  height: 10px;
  background-color: white;
}

.navbar-enabler-icon > div .top-bar {
  border-radius: 3px 3px 0 3px;
  transform: rotate(45deg) translateY(-4px);
}

.navbar-enabler-icon > div .bottom-bar {
  width: 20px;
  border-radius: 3px 0 0 3px;
  transform: rotate(-45deg) translate(-0.2px, 6.5px);
}

#navbar-enabler {
  display: none;
}

#navbar-enabler:checked + .navbar-enabler-icon {
  left: 200px;
}

#navbar-enabler:checked + .navbar-enabler-icon > .arrow-two {
  transform: rotate(180deg) translate(11px, 2px);
}

#navbar-enabler:checked + .navbar-enabler-icon > div > div {
  transition: 0.5s;
  background-color: red;
}

#navbar-enabler:checked ~ .navbar-body {
  width: 200px;
}

.navbar-body {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 0;
  overflow: hidden;
  z-index: 10;
  background-color: var(--bg-color-cards);
  transition: 0.5s;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.5);

  img {
    display: block;
    position: relative;
    left: 50%;
    transform: translate(-50%, 30px);
    height: 150px;
  }

  ul {
    position: relative;
    display: block;
    width: 100%;
    list-style: none;
    transform: translateY(5rem);
  }

  li {
    position: inherit;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    padding: 10px;
    margin-bottom: 5px;
    width: 100%;

    a {
      color: white;
      font-size: 1.3rem;
    }

    &:hover a::after {
      right: 5%;
      transition: 0.3s;
    }

    a::after {
      content: '';
      position: absolute;
      transition: 1s;
      top: 0;
      bottom: 0;
      left: 0;
      right: 100%;
      backdrop-filter: invert(1) grayscale(1);
      clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
    }

    a:visited {
      color: white;
    }
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (min-width: 424px) {
  #navbar-enabler:checked + .navbar-enabler-icon {
    left: 300px;
  }

  #navbar-enabler:checked ~ .navbar-body {
    width: 300px;
  }
}
