.about-container {
  position: relative;
  z-index: 5;

  width: 100%;

  padding: 0.5rem;

  h1 {
    text-align: center;
  }
}

.profile-pic {
  position: absolute;
  right: 0px;
  top: 0;
  z-index: 1;

  height: 100vh;
  width: auto;
}

.profile-pic img {
  height: 100vh;
  width: 100%;

  object-fit: cover;
}

.shadow-left {
  position: absolute;
  z-index: 2;

  width: 100%;
  height: 100%;

  background: linear-gradient(
    90deg,
    var(--black) 5%,
    rgba(26, 26, 26, 0.2) 100%
  );
}

.shadow-bottom {
  position: absolute;
  z-index: 3;

  width: 100%;
  height: 100%;

  background: linear-gradient(
    0deg,
    var(--black) 5%,
    rgba(26, 26, 26, 0.2) 100%
  );
}
