:root {
  --pink: hsl(312, 99%, 50%);
  --black: hsl(0, 0%, 10%);
  --bg-color-cards-light: hsl(240, 22%, 50%);
  --bg-color-cards: hsl(240, 22%, 20%);
  --white: hsl(0, 0%, 100%);
  --red: hsl(347, 93%, 50%);
  --dark-red: hsl(347, 93%, 40%);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  scroll-behavior: smooth;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  scrollbar-width: 0;
}

body {
  background-color: var(--black);
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

video {
  z-index: 0;
  object-fit: none;
  min-width: 1920px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--black);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--red);
}
