.sections {
  min-height: auto;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.wrapper {
  width: 100%;
  padding: 0 2rem;
}

.section-title-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
}

.section-title {
  width: auto;
  z-index: 2;
  padding: 7px 35px;
  border: 5px solid rgba(237, 008, 057, 0.8);
  border-radius: 50px;
  font-size: 30px;
  background-color: rgba(015, 010, 029, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  .blink-caret {
    font-size: 40px;
    font-weight: 900;
    animation: blinkCaret 1s step-end infinite;
    transform: translateX(5px) translateY(-5px);
  }
}

.section-background-line {
  z-index: 1;
  width: 100%;
  height: 20px;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(000, 000, 000, 0.1) 0%,
    rgba(237, 008, 057, 1) 50%,
    rgba(000, 000, 000, 0.1) 100%
  );
}

@keyframes blinkCaret {
  from,
  to {
    color: transparent;
  }
  50% {
    color: orange;
  }
}

@media (min-width:475px) {

  .wrapper {
    padding: 0 50px;
  }


}