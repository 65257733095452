@keyframes RotateRight {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes RotateLeft {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

.loading-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99;

  background-color: #333;
}

.loading1,
.loading2 {
  position: fixed;
  top: 50%;
  left: 50%;

  border: 10px solid transparent;
  border-radius: 50%;
}

.loading1 {
  height: 200px;
  width: 200px;

  border-bottom: 10px solid #fff;
  border-top: 10px solid #fff;

  animation: RotateRight 1s linear infinite;
}

.loading2 {
  height: 150px;
  width: 150px;

  border-bottom: 10px solid var(--red);
  border-top: 10px solid var(--red);

  animation: RotateLeft 1s linear infinite;
}
